import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Layout from '../containers/rifi_lending/layout';
import PrivateRoute from './PrivateRoute';
import routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import NotFound from 'components/rifi_lending/notFound/NotFound';
// import ComingSoonContainer from 'containers/rifi_lending/dashboard/DashboardContainer';
import ComingSoon from 'components/rifi_lending/comingSoon/ComingSoon';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// AOS.init();

const AppRouter = (
  <>
    <Suspense fallback={<div> </div>}>
      <Switch>
        {routes.map((route, key) => {
          const { path, exact, isPrivate, component, layout, isDashboard } =
            route;
          const props = {
            key,
            path,
            exact,
            isDashboard,
            component: lazy(
              () =>
                new Promise((resolve) =>
                  setTimeout(() => resolve(component), 100),
                ),
            ),
          };
          const element = isPrivate ? (
            <PrivateRoute {...props} />
          ) : (
            <Route {...props} />
          );
          if (layout === null) {
            return element;
          }
          return React.createElement(layout ? layout : Layout, props, element);
        })}
        <Route component={ComingSoon} />
      </Switch>
    </Suspense>
  </>
);

export default AppRouter;
