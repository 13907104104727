const SET_BALANCE_TOKENS = 'tokensWithWallet/SET_BALANCE_TOKENS';
const SET_DATA_TOKENS = 'tokensWithWallet/SET_DATA_TOKENS';
const SET_ALLOWANCE_TOKENS = 'tokensWithWallet/SET_ALLOWANCE_TOKENS';
const SET_INFO_ACCOUNT_WALLLET = 'tokensWithWallet/SET_INFO_ACCOUNT_WALLLET';
const SET_BALANCE_TOKENS_SUPPLIED =
  'tokensWithWallet/SET_BALANCE_TOKENS_SUPPLIED';
const SET_CHECK_MEMBER_SHIP_TOKENS =
  'tokensWithWallet/SET_CHECK_MEMBER_SHIP_TOKENS';
const SET_BALANCE_TOKENS_BORROWED =
  'tokensWithWallet/SET_BALANCE_TOKENS_BORROWED';
const SET_DEFAULT_STATE = 'tokensWithWallet/SET_DEFAULT_STATE';

export const initialState = {
  tokens: undefined,
  balancesOfTokenInWallet: undefined,
  allowances: undefined,
  infoAccountWallet: undefined,
  balancesSupplied: undefined,
  balancesBorrowed: undefined,
  checkMemberShips: undefined,
};

export const setDataTokens = (data) => ({
  type: SET_DATA_TOKENS,
  payload: {
    data: data.tokens,
    network: data.network,
  },
});
export const setBalanceTokens = (data) => ({
  type: SET_BALANCE_TOKENS,
  payload: {
    data: data,
  },
});
export const setAllowanceTokens = (data) => ({
  type: SET_ALLOWANCE_TOKENS,
  payload: {
    data: data,
  },
});
export const setInfoAccountWallet = (data) => ({
  type: SET_INFO_ACCOUNT_WALLLET,
  payload: {
    data: data,
  },
});

export const setBalanceTokensSupplied = (data) => ({
  type: SET_BALANCE_TOKENS_SUPPLIED,
  payload: {
    data: data,
  },
});

export const setBalanceTokensBorrowed = (data) => ({
  type: SET_BALANCE_TOKENS_BORROWED,
  payload: {
    data: data,
  },
});

export const setCheckMemberships = (data) => ({
  type: SET_CHECK_MEMBER_SHIP_TOKENS,
  payload: {
    data: data,
  },
});

export const setDefaultState = () => ({
  type: SET_DEFAULT_STATE,
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALLOWANCE_TOKENS:
      return {
        ...state,
        allowances: action.payload.data,
      };
    case SET_BALANCE_TOKENS:
      return {
        ...state,
        balancesOfTokenInWallet: action.payload.data,
      };
    case SET_INFO_ACCOUNT_WALLLET:
      return {
        ...state,
        infoAccountWallet: action.payload.data,
      };
    case SET_BALANCE_TOKENS_SUPPLIED:
      return {
        ...state,
        balancesSupplied: action.payload.data,
      };
    case SET_BALANCE_TOKENS_BORROWED:
      return {
        ...state,
        balancesBorrowed: action.payload.data,
      };
    case SET_CHECK_MEMBER_SHIP_TOKENS:
      return {
        ...state,
        checkMemberShips: action.payload.data,
      };
    case SET_DEFAULT_STATE:
      return {
        tokens: undefined,
        balancesOfTokenInWallet: undefined,
        allowances: undefined,
        infoAccountWallet: undefined,
        balancesSupplied: undefined,
        balancesBorrowed: undefined,
        checkMemberShips: undefined,
      };

    default:
      return state;
  }
};
