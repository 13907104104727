import {
  injected,
  walletlink,
  ledger,
  walletConnect,
  coin98,
  NETWORKS,
  MAINNET,
  BSC_MAINNET,
  BSC_TESTNET,
  RINKEBY,
} from './conntectors';
import Rifi from 'rifi-js';
import IconBinanceWallets from 'assets/rifi_lending/icons/ic_binance_wallets.svg';
import IconEthereumWallets from 'assets/rifi_lending/icons/ic_ethereum_wallets.svg';

export const ConnectorNames = {
  MetaMask: 'MetaMask',
  CoinBase: 'CoinBase',
  Ledger: 'Ledger',
  WalletConnect: 'WalletConnect',
  Coin98: 'Coin98',
};

export const connectorsByName = {
  [ConnectorNames.MetaMask]: injected,
  [ConnectorNames.CoinBase]: walletlink,
  [ConnectorNames.Ledger]: ledger,
  [ConnectorNames.WalletConnect]: walletConnect,
  [ConnectorNames.Coin98]: coin98,
};

export const SLIDER_INTRODUCE_SETTING = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5600,
  pauseOnHover: false,
};

export const NATIVE_COIN_MARKET = {
  underlying_symbol: 'ETH',
  symbol: 'cETH',
};

export const STANDART_COIN_MARKET = {
  underlying_symbol: 'USDC',
  symbol: 'cUSDC',
};

// change in binance
export const NATIVE_COIN = {
  underlying_symbol: Rifi.BNB,
  symbol: Rifi.rBNB,
};
// change in binance
export const STANDART_COIN = {
  [NETWORKS[BSC_MAINNET]]: {
    underlying_symbol: Rifi.BUSD,
    symbol: Rifi.rBUSD,
  },
  [NETWORKS[MAINNET]]: {
    underlying_symbol: Rifi.USDT,
    symbol: Rifi.rUSDT,
  },
  [NETWORKS[BSC_TESTNET]]: {
    underlying_symbol: Rifi.BUSD,
    symbol: Rifi.rBUSD,
  },
  [NETWORKS[RINKEBY]]: {
    underlying_symbol: Rifi.USDT,
    symbol: Rifi.rUSDT,
  },
};

export const MAX_LIMIT = 80; // unit: %

export const TYPE_HANDLE_REDEEM = {
  MAX: 'MAX',
};

export const TYPE_HANDLE_REPAY = {
  MAX: 'MAX',
  MAX_WALLET: 'MAX_WALLET',
};

export const TYPE_HANDLE_SUPPLY = {
  MAX: 'MAX',
};

export const NETWORK_MARKET = 'testnet';

export const TYPE_UNIT_MARKETS = {
  USD: 'USD',
  NATIVE: 'NATIVE',
};

export const TABS_DASHBOARD = [
  {
    value: 1,
    label: 'Supply Market',
  },
  {
    value: 2,
    label: 'Borrow Market',
  },
];

export const LIST_TWO_NETWORK = [
  {
    icon: IconBinanceWallets,
    name: 'Binance Smart Chain',
    chainId: NETWORKS[BSC_MAINNET],
  },
  {
    icon: IconEthereumWallets,
    name: 'Ethereum Mainnet',
    chainId: NETWORKS[MAINNET],
  },
];

export const NETWORK_BSC = [
  {
    icon: IconBinanceWallets,
    name: 'Binance Smart Chain',
    chainId: NETWORKS[BSC_MAINNET],
  },
];

export const NETWORK_BSC_TESTNET = [
  {
    icon: IconBinanceWallets,
    name: 'Binance Smart Chain - Testnet',
    chainId: NETWORKS[BSC_TESTNET],
  },
];

export const LIST_PAGE = {
  DASHBOARD: 'dashboard',
  MARKETS: 'markets',
  MARKET_ANALYSIS: 'market-analysis',
  FAQ: 'faq',
  VAULT: 'vault',
  LIQUIDATE: 'liquidate',
  BRIDGE: 'bridge',
  PRICE: 'price',
};

export const STATUS_PAGE = {
  COMING: 'coming',
  RELEASE: 'release',
};

export const ADDRESS_0 = '0x0000000000000000000000000000000000000000';

export const CURRENT_CONNECTED_WALLET = 'currentConnectedWallet';
