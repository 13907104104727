import Rifi from 'rifi-js';
import { NATIVE_COIN } from 'utilities/constants';
import { getProvider, NETWORKS } from 'utilities/conntectors';
import { chain, map } from 'lodash';
import { ethers, BigNumber } from 'ethers';

const ethMantissa = 1e18;
const blocksPerDay = 6570; // 13.15 seconds per block
const daysPerYear = 365;

export const getAllTokenBySDK = async (chainId) => {
  const network = Rifi.util.getNetNameWithChainId(
    chainId || NETWORKS['mainnet'],
  );

  const tokens = await Rifi.api.getSupportTokens(network);

  await Promise.all([
    getUnderlyingPrice(tokens),
    getSupplyRate(tokens),
    getBorrowRate(tokens),
    getCollateral(tokens),
    getCash(tokens),
    getExchangeRate(tokens),
  ]);
  return tokens;
};

const getExchangeRate = async (tokens) => {
  try {
    const provider = getProvider();
    const rifi = new Rifi(provider);

    for (const token of tokens.rToken) {
      rifi
        .tokenRead('exchangeRateStored', token.symbol)
        .then((exchangeRate) => {
          token.exchange_rate.value = exchangeRate * 10 ** -28;
        });
    }
  } catch (error) { }
};

const getCash = async (tokens) => {
  try {
    const provider = getProvider();
    const rifi = new Rifi(provider);

    await Promise.all(
      tokens.rToken.map((token) =>
        rifi.tokenRead('getCash', token.symbol).then((cash) => {
          token.cash.value = ethers.utils.formatUnits(
            cash,
            token.underlying_decimals,
          );
        }),
      ),
    );
  } catch (error) { }
};

const getCollateral = async (tokens) => {
  try {
    const provider = getProvider();
    const rifi = new Rifi(provider);
    await Promise.all(
      tokens.rToken.map((token) =>
        rifi.getCollateralFactor(token.symbol).then((result) => {
          token.collateral_factor.value = ethers.utils.formatUnits(
            result.collateralFactorMantissa,
            token.underlying_decimals,
          );
        }),
      ),
    );
  } catch (error) { }
};

const getUnderlyingPrice = async (tokens) => {
  try {
    const provider = getProvider();
    const rifi = new Rifi(provider);
    await Promise.all(
      tokens.rToken.map((token) =>
        rifi
          .getUnderlyingPrice(token.symbol)
          .then((price) => (token.underlying_price.value = price)),
      ),
    );
  } catch (error) { }
};

const getSupplyRate = async (tokens) => {
  try {
    const provider = getProvider();
    const rifi = new Rifi(provider);
    await Promise.all(
      tokens.rToken.map((token) =>
        rifi
          .tokenRead('supplyRatePerBlock', token.symbol)
          .then(
            (supplyRatePerBlock) =>
              Math.pow(
                (supplyRatePerBlock / ethMantissa) * blocksPerDay + 1,
                daysPerYear,
              ) - 1,
          )
          .then((supplyApy) => {
            token.supply_rate.value = supplyApy;
          }),
      ),
    );
  } catch (error) { }
};

const getBorrowRate = async (tokens) => {
  try {
    const provider = getProvider();
    const rifi = new Rifi(provider);

    await Promise.all(
      tokens.rToken.map((token) =>
        rifi
          .tokenRead('borrowRatePerBlock', token.symbol)
          .then(
            (borrowRatePerBlock) =>
              Math.pow(
                (borrowRatePerBlock / ethMantissa) * blocksPerDay + 1,
                daysPerYear,
              ) - 1,
          )
          .then((borrowApy) => {
            token.borrow_rate.value = borrowApy;
          }),
      ),
    );
  } catch (error) { }
};

export const getBalanceOfAllTokenSuppliedSDK = async (
  accountAddr,
  chainId,
  tokens,
) => {
  const promises = map(tokens, (item, index) =>
    getBalanceOfTokenSupplied(accountAddr, chainId, item),
  );
  const result = await Promise.all(promises);
  if (result && !result?.every((item) => item === undefined)) return result;
};

export const getBalanceOfTokenSupplied = async (
  accountAddr,
  chainId,
  token,
) => {
  try {
    const provider = getProvider();
    const { token_address: address, symbol, decimals: decimal } = token;

    const rifi = new Rifi(provider);
    const balance = await rifi.getBalanceOf(symbol, accountAddr);

    const balanceFormatByDecimal = ethers.utils.formatUnits(balance, decimal);
    return {
      symbol,
      address,
      balance,
      balanceFormatByDecimal,
      decimal,
    };
  } catch (ex) { }
};

export const getBalanceOfAllTokenBorrowedSDK = async (
  accountAddr,
  chainId,
  tokens,
) => {
  const promises = chain(tokens)
    .map((item) => getBalanceOfTokenBorrowed(accountAddr, chainId, item))
    .value();
  const result = await Promise.all(promises);
  if (result && !result?.every((item) => item === undefined)) return result;
};

export const getBalanceOfTokenBorrowed = async (
  accountAddr,
  chainId,
  token,
) => {
  try {
    const network = Rifi.util.getNetNameWithChainId(chainId);
    const provider = getProvider();
    const rifi = new Rifi(provider);

    const {
      underlying_decimals: uDecimals,
      token_address: address,
      symbol,
    } = token;

    const borrowBalanceStored = await rifi.getBorrowBalanceOf(
      symbol,
      accountAddr,
    );

    const balanceFormatByDecimal = ethers.utils.formatUnits(
      borrowBalanceStored,
      uDecimals,
    );

    return {
      symbol,
      address,
      network,
      borrowBalanceStored,
      balanceFormatByDecimal,
    };
  } catch (ex) { }
};

export const checkMembershipOfAllTokenSDK = async (
  accountAddress,
  chainId,
  listToken,
) => {
  const promises = map(listToken || [], (item) =>
    checkMembershipOfToken(accountAddress, chainId, item.symbol),
  );

  const result = await Promise.all(promises);
  return result;
};

export const checkMembershipOfToken = async (
  accountAddress,
  chainId,
  symbol,
) => {
  try {
    const network = Rifi.util.getNetNameWithChainId(chainId);
    const rToken = Rifi.util.getAddress(symbol, network);
    const provider = getProvider();
    const rifi = new Rifi(provider);
    const checkMembership = await rifi.checkMembership(accountAddress, symbol);

    return {
      checkMembership,
      network,
      rToken,
      symbol,
    };
  } catch (ex) { }
};

export const getBalanceOfAllTokenSDK = async (
  walletAddr,
  chainId,
  listAddressOwner,
) => {
  const promises = chain(listAddressOwner || [])
    .filter((item) => item.underlying_symbol !== NATIVE_COIN.underlying_symbol)
    .map((item) =>
      getBalanceOfToken(walletAddr, chainId, item.underlying_symbol),
    )
    .value();
  const result = await Promise.all(promises);
  return result;
};

export const getBalanceOfToken = async (walletAddr, chainId, tokenSymbol) => {
  try {
    const network = Rifi.util.getNetNameWithChainId(chainId);
    const provider = getProvider();
    const contractAddr = Rifi.util.getAddress(tokenSymbol, network);

    const [decimals, balance] = await Promise.all([
      Rifi.eth.read(contractAddr, 'function decimals() returns (uint8)', [], {
        network,
        provider,
      }),
      Rifi.eth.read(
        contractAddr,
        'function balanceOf(address) returns (uint256)',
        [walletAddr],
        { network, provider },
      ),
    ]);

    const balanceWithDecimals = ethers.utils.formatUnits(balance, decimals);
    return {
      underlying_symbol: tokenSymbol,
      underlying_address: contractAddr,
      balance_in_wallet: balanceWithDecimals,
      balance_in_wallet_bigNumber: balance,
      network,
    };
  } catch (ex) { }
};

export const getAllowanceOfAllTokenSDK = async (
  chainId,
  account,
  listContract,
) => {
  const listContractFallBack = listContract || [
    { underlying_symbol: Rifi.BAT, symbol: Rifi.rBAT },
    // { underlying_symbol: Rifi.COMP, symbol: Rifi.rCOMP },
    { underlying_symbol: Rifi.DAI, symbol: Rifi.rDAI },
    { underlying_symbol: Rifi.REP, symbol: Rifi.rREP },
    { underlying_symbol: Rifi.SAI, symbol: Rifi.rSAI },
    { underlying_symbol: Rifi.UNI, symbol: Rifi.rUNI },
    { underlying_symbol: Rifi.USDC, symbol: Rifi.rUSDC },
    { underlying_symbol: Rifi.USDT, symbol: Rifi.rUSDT },
    { underlying_symbol: Rifi.WBTC, symbol: Rifi.rWBTC },
    { underlying_symbol: Rifi.ZRX, symbol: Rifi.rZRX },
  ];
  const promises = chain(listContractFallBack)
    .filter((item) => item.symbol !== NATIVE_COIN.symbol)
    .map((item, index) =>
      getAllowanceOfToken(
        chainId,
        account,
        item.underlying_symbol,
        item.symbol,
      ),
    );

  const result = await Promise.all(promises);
  return result;
};

export const getAllowanceOfToken = async (
  chainId,
  account,
  contract,
  cContract,
) => {
  try {
    const network = Rifi.util.getNetNameWithChainId(chainId);
    const provider = getProvider();
    const rAddress = Rifi.util.getAddress(contract, network);
    const rcAddress = Rifi.util.getAddress(cContract, network);
    const allowance = await Rifi.eth.read(
      rAddress,
      'function allowance(address _owner, address _spender) public view returns (uint256 remaining)',
      [account, rcAddress],
      { network, provider },
    );
    const allowanceFormat =
      allowance && Number(ethers.utils.formatEther(allowance));
    return {
      allowanceBigNumberish: allowance,
      allowance: allowanceFormat,
      underlying_address: rAddress,
      token_address: rcAddress,
      network: network,
      underlying_symbol: contract,
      symbol: cContract,
    };
  } catch (ex) { }
};

export const CheckApprove = async (chainId, account, vaultAddress) => {
  try {
    const network = Rifi.util.getNetNameWithChainId(chainId);
    const provider = getProvider();
    const SAFE_ALLOWANCE = BigNumber.from('0xfffffffffffffffffffffff');

    const tokenAddress = await Rifi.eth.read(
      vaultAddress,
      'function depositToken() public view returns (address)',
      [],
      { network, provider },
    );

    const allowance = await Rifi.eth.read(
      tokenAddress,
      'function allowance(address _owner, address _spender) public view returns (uint256 remaining)',
      [account, vaultAddress],
      { network, provider },
    );
    return allowance.gt(SAFE_ALLOWANCE);
  } catch (ex) { }
};

export const enableVaultDeposit = async (chainId, vaultAddress) => {
  try {
    const network = Rifi.util.getNetNameWithChainId(chainId);
    const provider = getProvider();
    const valueMax =
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

    const tokenAddress = await Rifi.eth.read(
      vaultAddress,
      'function depositToken() public view returns (address)',
      [],
      { network, provider },
    );
    const approve = await Rifi.eth.trx(
      tokenAddress,
      'function approve(address _spender, uint256 _value) public returns (bool success)',
      [vaultAddress, valueMax],
      {
        provider,
        network,
      },
    );

    approve && (await approve.wait());
    return approve;
  } catch (ex) {
    return ex;
  }
};

export const getCloseFactor = async () => {
  try {
    const provider = getProvider();
    const rifi = new Rifi(provider);
    const results = await rifi.getCloseFactor();
    return results;
  } catch (ex) { }
};

export const getLiquidationIncentive = async () => {
  try {
    const provider = getProvider();
    const rifi = new Rifi(provider);
    const results = await rifi.getLiquidationIncentive();
    return results;
  } catch (ex) { }
};
