import React from 'react';
import IconComing from 'assets/rifi_lending/icons/ic_time_clock.svg';

const ComingSoon = () => {
  return (
    <div className='coming-soon'>
      <div className='coming-soon__content'>
        <div className='coming-box'>
          <img src={IconComing} alt='' />
          <div className='text-coming'>COMING SOON</div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
