import { round, find, forEach, chain, floor } from 'lodash';
import { STANDART_COIN } from './constants';
import { NETWORKS_SUPPORT } from './conntectors';
import { ethers, BigNumber } from 'ethers';
import Web3 from 'web3';

export const web3 = new Web3('https://bsc-dataseed.binance.org');

export const thounsandSeparators = (number, maximumFractionDigits) => {
  if (!number) return '0';
  //number string
  if (number > 1000) {
    number = Number(number);
  }
  //number string
  return number.toLocaleString('en-US', {
    maximumFractionDigits: maximumFractionDigits || 2,
  });
};

export const thounsandSeparatorsNoDigits = (number, maximumFractionDigits) => {
  if (number > 10000) {
    return number.toLocaleString('en-US', {
      maximumFractionDigits: 0,
    });
  }
  return number.toLocaleString('en-US', {
    maximumFractionDigits: maximumFractionDigits || 2,
  });
};

export const formatToUSDC = (number) => {
  const numberRounded = round(Number(number));
  const unit =
    numberRounded.toString().length > 9
      ? 'B'
      : numberRounded.toString().length > 6
      ? 'M'
      : numberRounded.toString().length > 3
      ? 'k'
      : '';
  if (unit === 'B') {
    const formatToUSDC = round(Number(numberRounded / 10 ** 9), 2);
    return `$${thounsandSeparators(formatToUSDC)}${unit}`;
  } else if (unit === 'M') {
    const formatToUSDC = round(Number(numberRounded / 1000000), 2);
    return `$${thounsandSeparators(formatToUSDC)}${unit}`;
  } else if (unit === 'k') {
    const formatToUSDC = round(Number(numberRounded / 1000), 2);
    return `$${thounsandSeparators(formatToUSDC)}${unit}`;
  } else {
    const formatToUSDC = round(Number(numberRounded), 2);
    return `$${thounsandSeparators(formatToUSDC)}`;
  }
};

export const formatNativeCoin = (number) => {
  const numberRounded = round(Number(number));
  const unit =
    numberRounded.toString().length > 9
      ? 'B'
      : numberRounded.toString().length > 6
      ? 'M'
      : numberRounded.toString().length > 3
      ? 'k'
      : '';
  if (unit === 'B') {
    const formatToUSDC = round(Number(numberRounded / 10 ** 9), 2);
    return `${thounsandSeparators(formatToUSDC)}${unit}`;
  } else if (unit === 'M') {
    const formatToUSDC = round(Number(numberRounded / 1000000), 2);
    return `${thounsandSeparators(formatToUSDC)}${unit}`;
  } else if (unit === 'k') {
    const formatToUSDC = round(Number(numberRounded / 1000), 2);
    return `${thounsandSeparators(formatToUSDC)}${unit}`;
  } else {
    const formatToUSDC = round(Number(numberRounded), 2);
    return `${thounsandSeparators(formatToUSDC)}`;
  }
};

export const getAPYCommon = (number) => {
  const valueRounded = round(Number(number) * 100, 2);
  return `${valueRounded}%`;
};

export const getValueUnderlyingUSDC = (array, chainId) => {
  const item = find(
    array,
    (item) =>
      item.underlying_symbol === STANDART_COIN[chainId]?.underlying_symbol,
  );
  if (item) {
    return Number(item.underlying_price.value);
  } else {
    return 1;
  }
};

export const getRateCoinUSDC = (coin, usdc) => {
  const rate = Number(coin) / Number(usdc);
  return rate;
};

export const getTotalSupply = (
  number,
  exchange_rate,
  priceInUSDC = 1.000482,
) => {
  const totalSupply = Number(number) * Number(exchange_rate) * priceInUSDC;
  return totalSupply;
  // return formatToUSDC(totalSupply);
};

export const getTotalBorrow = (number, priceInUSDC = 1.000482) => {
  const totalBorrow = Number(number) * priceInUSDC;
  return totalBorrow;
  // return formatToUSDC(totalBorrow);
};

export const changeSupplyAPY = (numberCurrent, numberBlock) => {
  const change = Number(numberCurrent) - Number(numberBlock);
  return round(change * 100, 2);
};
export const changeBorrowAPY = (numberCurrent, numberBlock) => {
  const change = Number(numberCurrent) - Number(numberBlock);
  return round(change * 100, 2);
};

export const getTokenByTokenAddress = (tokens, token_address) => {
  const token = find(tokens, (item) => item.token_address === token_address);
  return token;
};

export const getTokenBySymbol = (tokens, symbol) => {
  const token = find(tokens, (item) => item.symbol === symbol);
  return token;
};

export const getTotalSupplyAll = (listrToken) => {
  let totalSupplyAll = 0;
  forEach(listrToken, (item) => {
    totalSupplyAll = totalSupplyAll + Number(item.getTotalSupply);
  });
  return totalSupplyAll;
};

export const getTotalBorrowAll = (listrToken) => {
  let totalBorrowAll = 0;
  forEach(listrToken, (item) => {
    totalBorrowAll = totalBorrowAll + Number(item.getTotalBorrow);
  });
  return totalBorrowAll;
};

export const getChangeRateTwoNumber = (numberCurrent, numberBlock) => {
  if (Number(numberBlock) === 0) return undefined;
  const change = Number(numberCurrent) / Number(numberBlock) - 1;
  return round(change * 100, 2);
};

export const getFormatBalanceByBignumber = (wei, percestion = 4) => {
  if (!wei) return 0;
  return round(ethers.utils.formatEther(wei), percestion);
};

export const formatBalanceInWallet = (balance, percestion = 4) => {
  return balance ? round(balance, percestion) : 0;
};

export const formatFloatByPrecestion = (number, percestion = 2) => {
  return round(Number(number), percestion);
};

export const getTotalBalanceAllTokenSupplied = (
  balanceOfAllTokenSupplied,
  tokensResult,
  chainId,
) => {
  const valueUnderLyingUSDC =
    tokensResult && getValueUnderlyingUSDC(tokensResult, chainId);

  const totalBalanceAllTokenSupplied =
    balanceOfAllTokenSupplied &&
    chain(balanceOfAllTokenSupplied)
      .map((item) => {
        const itemFindInTokenResult =
          tokensResult &&
          find(tokensResult, (item1) => item1?.symbol === item?.symbol);
        const rateCoinUSDC = getRateCoinUSDC(
          itemFindInTokenResult?.underlying_price?.value,
          valueUnderLyingUSDC,
        );
        const totalSupply = getTotalSupply(
          item?.balanceFormatByDecimal,
          itemFindInTokenResult?.exchange_rate?.value,
          rateCoinUSDC,
        );
        return totalSupply;
      })
      .reduce((total, num) => {
        return total + num;
      }, 0)
      .value();
  return totalBalanceAllTokenSupplied || 0;
};

export const getTotalBalanceAllTokenBorrowing = (
  balancesOfAllTokenBorrowed,
  tokensResult,
  chainId,
) => {
  const valueUnderLyingUSDC =
    tokensResult && getValueUnderlyingUSDC(tokensResult, chainId);

  const totalBalanceAllTokenBorrowing =
    balancesOfAllTokenBorrowed &&
    chain(balancesOfAllTokenBorrowed)
      .map((item) => {
        const itemFindInTokenResult =
          tokensResult &&
          find(tokensResult, (item1) => item1?.symbol === item?.symbol);
        const rateCoinUSDC =
          itemFindInTokenResult &&
          getRateCoinUSDC(
            itemFindInTokenResult?.underlying_price?.value,
            valueUnderLyingUSDC,
          );
        const totalBorrow = getTotalBorrow(
          item?.balanceFormatByDecimal,
          rateCoinUSDC,
        );
        return totalBorrow;
      })
      .reduce((total, num) => {
        return total + num;
      }, 0)
      .value();

  return totalBalanceAllTokenBorrowing || 0;
};

export const getTotalSupplyEarnedByToken = (
  suppliedToken,
  rTokens,
  chainId,
) => {
  const cT = rTokens.find((cT) => cT.symbol === suppliedToken.symbol);
  const apy = Number(cT.supply_rate.value);
  const priceInUSDC = getRateCoinUSDC(
    cT.underlying_price.value,
    getValueUnderlyingUSDC(rTokens, chainId),
  );
  const supplyBalance = getTotalSupply(
    suppliedToken.balanceFormatByDecimal,
    cT.exchange_rate.value,
    priceInUSDC,
  );

  return apy * supplyBalance;
};

export const getFullTotalSupplyEarnedByToken = (
  suppliedToken,
  rTokens,
  chainId,
) => {
  const cT = rTokens.find((cT) => cT.symbol === suppliedToken.symbol);
  const apy = Number(cT.supply_rate.value + cT?.comp_supply_apy / 100);
  const priceInUSDC = getRateCoinUSDC(
    cT.underlying_price.value,
    getValueUnderlyingUSDC(rTokens, chainId),
  );
  const supplyBalance = getTotalSupply(
    suppliedToken.balanceFormatByDecimal,
    cT.exchange_rate.value,
    priceInUSDC,
  );

  return apy * supplyBalance;
};

export const getFullTotalSupplyEarned = (suppliedTokens, rTokens, chainId) => {
  if (!suppliedTokens) return;
  return suppliedTokens.reduce(
    (total, t) => total + getFullTotalSupplyEarnedByToken(t, rTokens, chainId),
    0,
  );
};

export const getTotalSupplyEarned = (suppliedTokens, rTokens, chainId) => {
  if (!suppliedTokens) return;
  return suppliedTokens.reduce(
    (total, t) => total + getTotalSupplyEarnedByToken(t, rTokens, chainId),
    0,
  );
};

export const getFullTotalBorrowAccruedByToken = (
  borrowedToken,
  rTokens,
  chainId,
) => {
  const cT = rTokens.find((cT) => cT?.symbol === borrowedToken?.symbol);
  const apy = Number(cT?.comp_borrow_apy / 100 - cT?.borrow_rate.value);
  const priceInUSDC = getRateCoinUSDC(
    cT?.underlying_price.value,
    getValueUnderlyingUSDC(rTokens, chainId),
  );
  const borrowBalance = getTotalBorrow(
    borrowedToken?.balanceFormatByDecimal,
    priceInUSDC,
  );

  return apy * borrowBalance;
};

export const getTotalBorrowAccruedByToken = (
  borrowedToken,
  rTokens,
  chainId,
) => {
  const cT = rTokens.find((cT) => cT?.symbol === borrowedToken?.symbol);
  const apy = Number(cT?.borrow_rate.value);
  const priceInUSDC = getRateCoinUSDC(
    cT?.underlying_price.value,
    getValueUnderlyingUSDC(rTokens, chainId),
  );
  const borrowBalance = getTotalBorrow(
    borrowedToken?.balanceFormatByDecimal,
    priceInUSDC,
  );

  return apy * borrowBalance;
};

export const getFullTotalBorrowAccrued = (borrowedTokens, rTokens, chainId) => {
  if (!borrowedTokens) return;
  return borrowedTokens.reduce(
    (total, t) => total + getFullTotalBorrowAccruedByToken(t, rTokens, chainId),
    0,
  );
};

export const getTotalBorrowAccrued = (borrowedTokens, rTokens, chainId) => {
  if (!borrowedTokens) return;
  return borrowedTokens.reduce(
    (total, t) => total + getTotalBorrowAccruedByToken(t, rTokens, chainId),
    0,
  );
};

export const getNetApy = (
  suppliedTokens,
  borrowedTokens,
  rTokens,
  totalSupplyBalance,
  chainId,
) => {
  const netAPY =
    (getTotalSupplyEarned(suppliedTokens, rTokens, chainId) -
      getTotalBorrowAccrued(borrowedTokens, rTokens, chainId)) /
    totalSupplyBalance;

  return formatFloatByPrecestion(netAPY * 100, 2);
};

export const getFullApy = (
  suppliedTokens,
  borrowedTokens,
  rTokens,
  totalSupplyBalance,
  chainId,
) => {
  const netAPY =
    (getFullTotalSupplyEarned(suppliedTokens, rTokens, chainId) -
      getFullTotalBorrowAccrued(borrowedTokens, rTokens, chainId)) /
    totalSupplyBalance;

  return formatFloatByPrecestion(netAPY * 100, 2);
};

export const getBorrowSupplyRatio = (
  suppliedTokens,
  borrowedTokens,
  rTokens,
  chainId,
) => {
  const totalBorrowAccrued = getTotalBorrowAccrued(
    borrowedTokens,
    rTokens,
    chainId,
  );
  const totalSupplyEarn = getTotalSupplyEarned(
    suppliedTokens,
    rTokens,
    chainId,
  );

  return totalBorrowAccrued / (totalBorrowAccrued + totalSupplyEarn);
};

export const getMaxLimitBorrow = (
  balanceOfAllTokenSupplied,
  tokensResult,
  memberShips,
  chainId,
) => {
  if (!memberShips) return 0;
  const valueUnderLyingUSDC =
    tokensResult && getValueUnderlyingUSDC(tokensResult, chainId);

  const maxLimitBorrow =
    balanceOfAllTokenSupplied &&
    chain(balanceOfAllTokenSupplied)
      .map((item) => {
        const itemFindInTokenResult =
          tokensResult &&
          find(tokensResult, (item1) => item1?.symbol === item?.symbol);
        const rateCoinUSDC = getRateCoinUSDC(
          itemFindInTokenResult?.underlying_price?.value,
          valueUnderLyingUSDC,
        );
        const totalSupply = getTotalSupply(
          item?.balanceFormatByDecimal,
          itemFindInTokenResult?.exchange_rate?.value,
          rateCoinUSDC,
        );
        const valueCollareralFactor = Number(
          itemFindInTokenResult?.collateral_factor?.value,
        );
        const memberShip = find(
          memberShips,
          (item2) => item2?.symbol === item?.symbol,
        );
        return (
          totalSupply *
          valueCollareralFactor *
          (memberShip?.checkMembership ? 1 : 0)
        );
      })
      .reduce((total, num) => {
        return total + num;
      }, 0)
      .value();
  return maxLimitBorrow || 0;
};

export const convertExponential = (n) => {
  var sign = +n < 0 ? '-' : '',
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  var [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, '')
    .replace(/^([0-9]+)(e.*)/, '$1.$2')
    .split(/e|\./);
  return +pow < 0
    ? sign +
        '0.' +
        '0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
        lead +
        decimal
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
};

export const formatToPercent = (number) => {
  const valueRounded = round(Number(number) * 100, 2);
  if (isNaN(valueRounded)) {
    return 0;
  } else {
    return `${valueRounded}%`;
  }
};

export const formatTokenAddress = (address) => {
  if (!address) {
    return '';
  }
  return address.slice(0, 4) + '...' + address.slice(address.length - 4);
};

export const formatCoinStat = (number, type = '') => {
  if (typeof number === 'string') return number;
  if (!number && number !== 0) return '0';

  const absNumber = Math.abs(number);
  let num = 1;

  if (absNumber > 1) {
    num =
      type === 'stable'
        ? formatFloatByPrecestion(number, 4)
        : formatFloatByPrecestion(number, 2);
  } else if (absNumber === 1) {
    num = number;
  } else if (absNumber < 1) {
    const zeros = Math.ceil(Math.abs(Math.log10(absNumber))) - 1;
    num =
      type === 'change'
        ? formatFloatByPrecestion(number, zeros + 2)
        : formatFloatByPrecestion(number, zeros + 3);
  }

  return num.toLocaleString('en-US', {
    maximumFractionDigits: 15,
    minimumFractionDigits: 2,
  });
};

export const getDomainNameFromUrl = (url) => {
  return url.split('/')[2];
};

export const getChangePercent = (newNumber, oldNumber) => {
  return (newNumber / oldNumber - 1) * 100;
};

const exponentialToDecimal = (exponential) => {
  let decimal = exponential.toString().toLowerCase();
  if (decimal.includes('e+')) {
    const exponentialSplitted = decimal.split('e+');
    let postfix = '';
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
        (exponentialSplitted[0].includes('.')
          ? exponentialSplitted[0].split('.')[1].length
          : 0);
      i++
    ) {
      postfix += '0';
    }
    const addCommas = (text) => {
      let j = 3;
      let textLength = text.length;
      while (j < textLength) {
        text = `${text.slice(0, textLength - j)},${text.slice(
          textLength - j,
          textLength,
        )}`;
        textLength++;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace('.', '') + postfix);
  }
  if (decimal.toLowerCase().includes('e-')) {
    const exponentialSplitted = decimal.split('e-');
    let prefix = '0.';
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += '0';
    }
    decimal = prefix + exponentialSplitted[0].replace('.', '');
  }
  return decimal;
};

export const roundNumber = (number, decimalPlaces = 4, maxLength = 100) => {
  let num2str = number + '';
  if (num2str.indexOf('e') > -1) {
    num2str = exponentialToDecimal(num2str);
  }
  if (num2str.indexOf('.') > -1) {
    const arr = num2str.split('.');
    let totalZeroHeader = 0;
    for (let i = 0; i < arr[1].length; i++) {
      if (arr[1][i] === '0') {
        totalZeroHeader++;
      } else {
        break;
      }
    }
    if (totalZeroHeader > 1) {
      return arr[0] + '.' + arr[1].substring(0, totalZeroHeader + 2);
    }
  }
  // maxLength is the maximum length of the integer part. Eg: 123.45 -> length = 3
  if (parseInt(number).toString().length > maxLength) {
    decimalPlaces = 0;
  }
  let valueRounded = round(Number(number), decimalPlaces);
  if (parseInt(valueRounded) > 1000) {
    return round(Number(number), 0);
  }
  return valueRounded;
};

export const copytoClipboard = (content) => {
  navigator.clipboard.writeText(content);
};

export const validatePositiveNumber = (number) => {
  if (!number) return false;
  const regex = /^$|^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/;
  return regex.test(number);
};

export const validateApproximate = (number) => {
  if (Number.isInteger(Number(number))) return Number(number);
  const numberSplit = String(Number.parseFloat(number).toFixed(18)).split('.');
  if (numberSplit && numberSplit.length > 1) {
    const integerNumber = numberSplit[0];
    const numberDigits = numberSplit[1];
    const newNumberDigits = numberDigits.slice(0, 8);
    const newNumber = Number.parseFloat(
      `${integerNumber}.${newNumberDigits}`,
    ).toFixed(8);
    return `~${newNumber}`;
  }
};

export const parseUnitsCustom = (value, decimals) => {
  let amountFormat = '';
  if (!value.toString().includes('.')) {
    amountFormat = value;
  } else {
    const length = value.toString().indexOf('.') + decimals + 1; // Length of decimal part always have to <= decimals
    const fixedAmout = value.toString().substring(0, length);
    amountFormat = convertExponential(fixedAmout);
  }
  return BigNumber.from(
    ethers.utils.parseUnits(amountFormat.toString(), decimals).toString(),
  );
};

export const isSupportNetwork = (chainId) => {
  if (!chainId) return false;
  const chainIdSupport = Object.values(NETWORKS_SUPPORT);
  if (chainIdSupport.indexOf(chainId) !== -1) return true;
  else {
    return false;
  }
};

export const isObject = (obj) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

export const getLinkForToAddress = (chainId, type, address) => {
  if (type === 'bsc2eth') {
    if (chainId === 97 || chainId === 4) {
      return 'https://rinkeby.etherscan.io/address/' + address;
    }
    return 'https://etherscan.io/address/' + address;
  }

  if (type === 'eth2bsc') {
    if (chainId === 97 || chainId === 4) {
      return 'https://testnet.bscscan.com/address/' + address;
    }
    return 'https://bscscan.com/address/' + address;
  }
};

export const getLinkForTxLock = (chainId, type, tx) => {
  if (type === 'bsc2eth') {
    if (chainId === 97 || chainId === 4) {
      return 'https://testnet.bscscan.com/tx/' + tx;
    }
    return 'https://bscscan.com/tx/' + tx;
  }

  if (type === 'eth2bsc') {
    if (chainId === 97 || chainId === 4) {
      return 'https://rinkeby.etherscan.io/tx/' + tx;
    }
    return 'https://etherscan.io/tx/' + tx;
  }
};

export const getLinkForTxUnlock = (chainId, type, tx) => {
  if (type === 'bsc2eth') {
    if (chainId === 97 || chainId === 4) {
      return 'https://rinkeby.etherscan.io/tx/' + tx;
    }
    return 'https://etherscan.io/tx/' + tx;
  }

  if (type === 'eth2bsc') {
    if (chainId === 97 || chainId === 4) {
      return 'https://testnet.bscscan.com/tx/' + tx;
    }
    return 'https://bscscan.com/tx/' + tx;
  }
};

/* Convert strings like "2020-12-25T17:03:20.962454Z" into strings like "5m ago" */
export const formatTime = (timeStr) => {
  const date = new Date();
  const now = new Date();
  date.setUTCFullYear(parseInt(timeStr.slice(0, 5)));
  date.setUTCMonth(parseInt(timeStr.slice(5, 7)) - 1);
  date.setUTCDate(parseInt(timeStr.slice(8, 10)));
  date.setUTCHours(parseInt(timeStr.slice(11, 13)));
  date.setUTCMinutes(parseInt(timeStr.slice(14, 16)));
  date.setUTCSeconds(parseInt(timeStr.slice(17, 19)));
  const seconds = (now.getTime() - date.getTime()) / 1000; // unit: second
  if (seconds < 60) {
    return `${floor(seconds)}s ago`;
  }
  const minutes = seconds / 60;
  if (minutes < 60) {
    return `${floor(minutes)}m ago`;
  }
  const hours = minutes / 60;
  if (hours < 24) {
    return `${floor(hours)}h ago`;
  }
  const days = hours / 24;
  if (days < 31) {
    return `${floor(days)}d ago`;
  }
  const months = days / 30;
  if (months < 13) {
    return `${floor(months)}m ago`;
  }
  const years = months / 12;
  return `${floor(years)}y ago`;
};

export const checkStatusPage = (array = [], page) => {
  const item = find(array, (item) => item.page === page);
  if (item) {
    return item.status;
  }
  return;
};

export const isCtrlCopyPaste = (evt) => {
  evt = evt || window.event;
  const c = evt.keyCode;
  const ctrlDown = evt.ctrlKey || evt.metaKey;

  const keyCodes = [65, 67, 86, 88];

  if (ctrlDown && keyCodes.indexOf(c) > -1) {
    return true;
  }

  return false;
};

export function capitalize(s) {
  return s && s.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export const isTimeInRage = (time, start, end) => {
  const startDate = start === -Infinity ? start : parseInt(start);
  const endDate = end === Infinity ? end : parseInt(end);
  if (startDate <= time && time <= endDate) {
    return true;
  } else {
    return false;
  }
};

export const cutRedundantDecimal = (value, decimalOfToken) => {
  return value.toString().substring(0, value.indexOf('.') + 1 + decimalOfToken);
};
