import React from 'react';
// import Footer from './Footer';
// import HeaderDashboard from './HeaderDashboard';

import { useEagerConnect, useInactiveListener } from 'utilities/hooks';
import { useWeb3React } from '@web3-react/core';

// import { connectorsByName } from 'utilities/constants';
import Sidebar from './Sidebar';
// import StatusBar from './StatusBar';

const DashboardLayout = (props) => {
  const { children } = props;
  const context = useWeb3React();
  const { connector } = context;

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useLayoutEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  // const handleConnectWallet = (
  //   name,
  //   callBackStartConnect,
  //   callBackCloseDialog,
  // ) => {
  //   callBackStartConnect();
  //   const currentConnector = connectorsByName[name];
  //   const activating = currentConnector === activatingConnector;
  //   const connected = currentConnector === connector;
  //   const disabled =
  //     !triedEager || !!activatingConnector || connected || !!error;

  //   setActivatingConnector(currentConnector);
  //   activate(connectorsByName[name]);

  //   if (active && !activating && disabled) {
  //     callBackCloseDialog();
  //   }
  // };

  return (
    <div className='content-app'>
      <div className='content-app-left'>
        <Sidebar />
      </div>
      <div className='content-app-right'>
        <div className='layout-content'>{children}</div>
      </div>
    </div>
  );
};

export default DashboardLayout;
