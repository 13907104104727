import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as tokensPublic } from './tokensPublic';
import { reducer as tokensWithWallet } from './tokensWithWallet';
import { reducer as tokensByNetWork } from './tokensByNetWork';
import { reducer as waiting } from './waiting';
import { reducer as connectWallet } from './connectWallet';
import { reducer as rifi } from './rifi';
import { reducer as transaction } from './transaction';
import { reducer as form } from 'redux-form';
import { reducer as filterLiquidate } from './filterLiquidate';
import { reducer as statusPage } from './statusPage';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    tokensPublic,
    tokensWithWallet,
    tokensByNetWork,
    waiting,
    connectWallet,
    rifi,
    transaction,
    form: form.plugin({ filterLiquidate }),
    statusPage,
  });

export default rootReducer;
