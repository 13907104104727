const SET_DATA_TOKENS = 'tokensByNetWork/SET_DATA_TOKENS';
const SET_DATA_TOKENS_DEFAULT = 'tokensByNetWork/SET_DATA_TOKENS_DEFAULT';

export const initialState = {
  tokens: undefined,
  tokensDefault: undefined,
};

export const setDataTokensByNetWork = (data) => ({
  type: SET_DATA_TOKENS,
  payload: {
    tokens: data,
  },
});

export const setDataTokensDefault = (data) => ({
  type: SET_DATA_TOKENS_DEFAULT,
  payload: {
    tokensDefault: data,
  },
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_TOKENS:
      return {
        ...state,
        tokens: action.payload.tokens,
      };
    case SET_DATA_TOKENS_DEFAULT:
      return {
        ...state,
        tokensDefault: action.payload.tokensDefault,
      };

    default:
      return state;
  }
};
