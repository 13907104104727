import React from 'react';
import { Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LendingLogo from 'assets/rifi_lending/home/ic_logo_black.svg';
import IconFacebook from 'assets/home/ic_facebook.svg';
import IconInstagram from 'assets/home/ic_instagram.svg';

const Footer = (props) => {
  return (
    <div className='footer'>
      <Container className='footer-container'>
        <Row className='footer-container-left'>
          <img src={LendingLogo} alt='Rifi logo' className='logo-footer' />
          <span className='nav-logo-text text-rifi'>Rikkei Finance</span>
        </Row>
        <Row className='footer-container-right'>
          <div className='right-contact'>
            <Link to='/'>
              <span className='nav-item-text text-rifi'>Contact</span>
            </Link>
            <Link to='/'>
              <span className='nav-item-text text-rifi'>Feedback</span>
            </Link>
            <Link to='/'>
              <span className='nav-item-text text-rifi'>Join Our Telegram</span>
            </Link>
            <Link to='/'>
              <span className='nav-item-text text-rifi'>Terms</span>
            </Link>
          </div>
          <div>
            <Link to='/'>
              <img src={IconFacebook} alt='icon facebook' className='mr-md-3' />
            </Link>
            <Link to='/'>
              <img src={IconInstagram} alt='icon instagram' />
            </Link>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
