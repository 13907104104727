import { ConnectedRouter } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getStatusApp } from 'utilities/services';
import './App.css';
import routers from './routers';
require('./assets/rifi_lending/scss/style.scss');

const App = ({ history }) => {
  const dispatch = useDispatch();
  dispatch(getStatusApp());

  return (
    <>
      <ConnectedRouter history={history}>{routers}</ConnectedRouter>
      <ToastContainer />
    </>
  );
};
export default App;
