import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import LendingLogo from 'assets/rifi_lending/home/ic_logo.svg';
import {
  COMING_SOON,
  DASHBOARD_PATH,
  MARKETS_PATH,
  MARKET_ANALYSIS,
} from 'configs/route_path';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';

const MenuHeader = ({ actionBtn }) => {
  const history = useHistory();
  return (
    <>
      <Navbar expand='lg' className='nav'>
        <Navbar.Brand className='logo-xlending'>
          <a href={'https://rikkei.finance/'}>
            <img src={LendingLogo} alt='xLending logo' className='logo'></img>
            <div className='nav-logo-text'>Rikkei Finance</div>
          </a>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls='basic-navbar-nav'
          className='nav-toggler'
        />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='w-100 d-flex justify-content-between align-items-center'>
            <NavLink to={DASHBOARD_PATH} exact>
              <span className='nav-item-text nav-hover'>Dashboard</span>
            </NavLink>

            <NavLink to={MARKETS_PATH} exact>
              <span className='nav-item-text nav-hover'>Markets</span>
            </NavLink>
            <NavLink to={MARKET_ANALYSIS}>
              <span className='nav-item-text nav-hover'>Market Analysis</span>
            </NavLink>

            <NavLink to={COMING_SOON}>
              <span className='nav-item-text nav-hover'>Price</span>
            </NavLink>

            <NavLink to={COMING_SOON}>
              <span className='nav-item-text nav-hover'>Governance</span>
            </NavLink>

            {/* <NavLink to={PORTFOLIO}>
              <span className='nav-item-text nav-hover'>Portfolio</span>
            </NavLink> */}

            {actionBtn || (
              <button
                className='button button-connect'
                onClick={() => history.push(DASHBOARD_PATH)}
              >
                Connect Wallet
              </button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default MenuHeader;
