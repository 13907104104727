import http from './http';
import Web3 from 'web3';
import Rifi from 'rifi-js';
import { NATIVE_COIN } from 'utilities/constants';
import { filter, find } from 'lodash';
import {
  setAllowanceTokens,
  setBalanceTokens,
  setInfoAccountWallet,
  setBalanceTokensSupplied,
  setCheckMemberships,
  setBalanceTokensBorrowed,
  // setDefaultState,
} from 'reducers/tokensWithWallet';
import {
  setDataWaiting,
  closeWaiting,
  closeWaitingBalance,
  setDataWaitingBalance,
} from 'reducers/waiting';
import {
  setDataTokensByNetWork,
  setDataTokensDefault,
} from 'reducers/tokensByNetWork';
import { setRifiData } from 'reducers/rifi';

import {
  selectTokensByNetWork,
  selectBalancesSupplied,
} from 'reducers/reselector';
import {
  getBalanceOfAllTokenSuppliedSDK,
  getBalanceOfAllTokenBorrowedSDK,
  checkMembershipOfAllTokenSDK,
  getBalanceOfAllTokenSDK,
  getAllowanceOfAllTokenSDK,
} from './servicesSDK';
import {
  TYPE_HANDLE_REDEEM,
  TYPE_HANDLE_REPAY,
  ADDRESS_0,
} from 'utilities/constants';
import { setDataTokens } from 'reducers/tokensPublic';
import { ethers, BigNumber } from 'ethers';
import { setTransactionHash } from 'reducers/transaction';
import {
  BSC_MAINNET,
  getProvider,
  NETWORKS,
  NETWORK_INFO,
} from './conntectors';
import { getStatusPage } from './client_api';
import { setStatusPageData } from 'reducers/statusPage';

export const fetchListToken = async (params = { meta: true }) => {
  try {
    const result = await http.get('rtoken', {
      params,
    });

    // TODO: Should change to actual Rifi's API data.
    // if (result.cToken) {
    //   result.rToken = result.cToken;
    //   delete result.cToken;
    // }
    return result;
  } catch (err) {
    throw err;
  }
};

export const getAllTokenByNetWork =
  (chainId = NETWORKS[BSC_MAINNET], accountAddress) =>
    async (
      dispatch,
      // getState,
    ) => {
      try {
        if (chainId) {
          dispatch(setDataWaiting());
        }
        const network = Rifi.util.getNetNameWithChainId(chainId);
        const tokens = await fetchListToken({ network });
        const tokenBlocks = await fetchListToken({
          meta: true,
          network: network,
          block_timestamp: Math.ceil(Date.now()) - 86400000, // timestamp ms
        });

        if (tokens) {
          if (tokenBlocks) {
            dispatch(
              setDataTokens({ tokensBlock: tokenBlocks, tokensCurrent: tokens }),
            );
          }
          if (chainId) {
            dispatch(setDataWaitingBalance());
            dispatch(setDataTokensByNetWork(tokens));
            const { rToken } = tokens;
            const result = await Promise.all([
              dispatch(
                getBalanceOfAllTokenSupplied(accountAddress, chainId, rToken),
              ),
              dispatch(
                getBalanceOfAllTokenBorrowed(accountAddress, chainId, rToken),
              ),
              dispatch(
                checkMembershipOfAllToken(accountAddress, chainId, rToken),
              ),
              dispatch(getBalanceOfAllToken(accountAddress, chainId, rToken)),
              dispatch(getAllowanceOfAllToken(chainId, accountAddress, rToken)),
            ]);
            if (result) {
              dispatch(closeWaitingBalance());
              dispatch(closeWaiting());
            }

            // dispatch(getBalanceOfAllTokenSupplied(accountAddress, chainId, rToken));
            // dispatch(getBalanceOfAllTokenBorrowed(accountAddress, chainId, rToken));

            // dispatch(checkMembershipOfAllToken(accountAddress, chainId, rToken));
            // // get balance of all token by ERC-20
            // dispatch(getBalanceOfAllToken(accountAddress, chainId, rToken));
            // // get allownce all token by ERC-20
            // dispatch(getAllowanceOfAllToken(chainId, accountAddress, rToken));
          } else {
            dispatch(setDataTokensDefault(tokens));
            dispatch(closeWaiting());
            dispatch(closeWaitingBalance());
          }
        }
        return tokens;
      } catch (ex) { }
    };

export const getBalanceOfAllToken =
  (walletAddr, chainId, listAddressOwner) => async (dispatch, getState) => {
    const result = await getBalanceOfAllTokenSDK(
      walletAddr,
      chainId,
      listAddressOwner,
    );
    if (result) {
      dispatch(setBalanceTokens(result));
    }
    return result;
  };

export const getAllowanceOfAllToken =
  (chainId, account, listContract) => async (dispatch, getState) => {
    const result = await getAllowanceOfAllTokenSDK(
      chainId,
      account,
      listContract,
    );
    if (result) {
      dispatch(setAllowanceTokens(result));
    }
    return result;
  };

export const getInforAccountWallet =
  (accountAddr, chainId) => async (dispatch, getState) => {
    try {
      const network = Rifi.util.getNetNameWithChainId(chainId);
      const account = await Rifi.api.account({
        addresses: accountAddr,
        network: network,
      });
      if (account) {
        dispatch(setInfoAccountWallet(account));
      }
    } catch (ex) {
      // console.log('ex getInforAccountWallet', ex);
    }
  };

export const getBalanceOfAllTokenSupplied =
  (accountAddr, chainId, tokens) => async (dispatch, getState) => {
    const result = await getBalanceOfAllTokenSuppliedSDK(
      accountAddr,
      chainId,
      tokens,
    );
    if (result) {
      const resultFilter = filter(
        result,
        (item) => Number(item?.balanceFormatByDecimal) !== 0,
      );
      dispatch(setBalanceTokensSupplied(resultFilter));
    }
    return result;
  };

export const getBalanceOfAllTokenBorrowed =
  (accountAddr, chainId, tokens) => async (dispatch, getState) => {
    const result = await getBalanceOfAllTokenBorrowedSDK(
      accountAddr,
      chainId,
      tokens,
    );
    if (result) {
      const resultFilter = filter(
        result,
        (item) => Number(item?.balanceFormatByDecimal) !== 0,
      );
      dispatch(setBalanceTokensBorrowed(resultFilter));
    }
    return result;
  };

export const supplyToken =
  (money, token, chainId, accountAddr, type, callBackCloseConfirm = () => { }) =>
    async (dispatch, getState) => {
      try {
        let result;
        const provider = getProvider();

        if (token.symbol === NATIVE_COIN.symbol) {
          // const moneyInWei = ethers.utils.parseUnits(money, token?.underlying_decimals);
          const network = Rifi.util.getNetNameWithChainId(chainId);
          const cContractAddr = Rifi.util.getAddress(token.symbol, network);
          let options = {
            provider,
            value: money,
          };
          // handle for max supply native case
          // if (type === TYPE_HANDLE_SUPPLY['MAX']) {
          //   const web3 = new Web3(provider);
          //   const block = await web3.eth.getBlock('latest');
          //   const gasLimit = block.gasLimit || 8000000;
          //   const lenghBlockTransactions = block.transactions.length || 1;
          //   const gasLimitEstimate = Math.ceil(gasLimit / lenghBlockTransactions);
          //   console.log(
          //     'block-gasLimit-lenghBlockTransactions-gasLimitEstimate',
          //     block,
          //     gasLimit,
          //     lenghBlockTransactions,
          //     gasLimitEstimate,
          //   );
          //   // unit gas : Gwei.
          //   // unit ether: 1e+18
          //   // gas price default: 1e-9 Gwei

          //   const gasPriceDefault = 1000000000; // = 1 Gwei
          //   // recipe: Transaction Fee( Ether ) = Gas Price(Gwei) * Gas Used by Transaction(Gwei).
          //   let transactionFreeEstimate =
          //     gasPriceDefault * 1e-9 * gasLimitEstimate * 1e-9;

          //   transactionFreeEstimate = ethers.utils.parseUnits(
          //     transactionFreeEstimate.toString(),
          //   );
          //   // transactionFreeEstimate = estimateSupplyFee;
          //   // const valueEstimate = money - transactionFreeEstimate * 1e18;
          //   const valueEstimate = BigNumber.from(money).sub(
          //     transactionFreeEstimate,
          //   );

          //   options = {
          //     ...options,
          //     // gasLimit: gasLimitEstimate,
          //     // gasPrice: gasPriceDefault,
          //     value: valueEstimate.toString(),
          //   };
          // }
          result = await Rifi.eth.trx(
            cContractAddr,
            'function mint() payable',
            [],
            options,
          );
        } else {
          const rifi = new Rifi(provider);
          // const moneyBigNumber = ethers.utils.parseUnits(money.toString().toString());
          const trxOptions = { mantissa: true };
          result = await rifi.supply(
            token.underlying_symbol,
            money,
            false,
            trxOptions,
          );
        }

        if (result) {
          const wait = await result.wait();
          if (wait.status === 1) {
            const tokensByNetWork = selectTokensByNetWork(getState());
            const { rToken } = tokensByNetWork;
            dispatch(getBalanceOfAllTokenSupplied(accountAddr, chainId, rToken));
            dispatch(getBalanceOfAllToken(accountAddr, chainId, rToken));
            dispatch(setTransactionHash(wait.transactionHash));
            // dispatch(getInforAccountWallet(accountAddr, chainId));
          }
        }

        return result;
      } catch (ex) {
        console.log(ex);
        if (
          ex?.error?.code === 4001 ||
          ex?.error?.message === 'User rejected the transaction'
        )
          callBackCloseConfirm();
      }
    };

export const redeemToken =
  (money, token, chainId, accountAddr, type, callBackCloseConfirm = () => { }) =>
    async (dispatch, getState) => {
      try {
        // const moneyInWei = Number(money) * 1e18;
        // const moneyWithdraw = Number(money);
        let trxOptions = { mantissa: true };
        let moneyBigNumber = '';
        let asset = '';
        // handle for max withdraw case
        if (type && type === TYPE_HANDLE_REDEEM['MAX']) {
          const balancesSupplied = selectBalancesSupplied(getState());
          const tokenSuppliedFind = find(
            balancesSupplied,
            (item) => item.symbol === token.symbol,
          );
          moneyBigNumber = BigNumber.from(tokenSuppliedFind.balance);
          asset = token.symbol;
        } else {
          moneyBigNumber = money;
          asset = token.underlying_symbol;
        }
        const rifi = new Rifi(getProvider());
        const trx = await rifi.redeem(asset, moneyBigNumber, trxOptions);
        if (trx) {
          const wait = await trx.wait();
          if (wait.status === 1) {
            const tokensByNetWork = selectTokensByNetWork(getState());
            const { rToken } = tokensByNetWork;
            dispatch(getBalanceOfAllTokenSupplied(accountAddr, chainId, rToken));
            dispatch(getBalanceOfAllToken(accountAddr, chainId, rToken));
            dispatch(setTransactionHash(wait.transactionHash));
            // dispatch(getInforAccountWallet(accountAddr, chainId));
          }
        }
        return trx;
      } catch (ex) {
        // console.log('ex redeemToken: ', ex);
        if (
          ex?.error?.code === 4001 ||
          ex?.error?.message === 'User rejected the transaction'
        )
          callBackCloseConfirm();
      }
    };

export const borrowToken =
  (
    money,
    underlying_symbol,
    chainId,
    accountAddr,
    callBackCloseConfirm = () => { },
  ) =>
    async (dispatch, getState) => {
      try {
        const rifi = new Rifi(getProvider());
        const options = { mantissa: true };
        const trx = await rifi.borrow(underlying_symbol, money, options);
        if (trx) {
          const wait = await trx.wait();
          if (wait.status === 1) {
            const tokensByNetWork = selectTokensByNetWork(getState());
            const { rToken } = tokensByNetWork;
            dispatch(getBalanceOfAllTokenBorrowed(accountAddr, chainId, rToken));
            dispatch(getBalanceOfAllToken(accountAddr, chainId, rToken));
            dispatch(setTransactionHash(wait.transactionHash));
            // dispatch(getInforAccountWallet(accountAddr, chainId));
          }
        }
        return trx;
      } catch (ex) {
        // console.log('ex borrowToken: ', ex);
        if (
          ex?.error?.code === 4001 ||
          ex?.error?.message === 'User rejected the transaction'
        )
          callBackCloseConfirm();
      }
    };

export const repayBorrowToken =
  (money, token, chainId, accountAddr, type, callBackCloseConfirm = () => { }) =>
    async (dispatch, getState) => {
      try {
        const rifi = new Rifi(getProvider());
        const maxRepay = type === TYPE_HANDLE_REPAY['MAX'];
        const address = maxRepay ? accountAddr : null;

        const trx = await rifi.repayBorrow(
          token.underlying_symbol,
          money,
          address,
          true,
          {
            mantissa: true,
            maxRepay,
          },
        );
        if (trx) {
          const wait = await trx.wait();
          if (wait.status === 1) {
            const tokensByNetWork = selectTokensByNetWork(getState());
            const { rToken } = tokensByNetWork;
            dispatch(getBalanceOfAllTokenBorrowed(accountAddr, chainId, rToken));
            dispatch(getBalanceOfAllToken(accountAddr, chainId, rToken));
            dispatch(setTransactionHash(wait.transactionHash));
            // dispatch(getInforAccountWallet(accountAddr, chainId));
          }
        }
        return trx;
      } catch (ex) {
        if (
          ex?.error?.code === 4001 ||
          ex?.error?.message === 'User rejected the transaction'
        )
          callBackCloseConfirm();
      }
    };

export const liquidateBorrowToken =
  (
    borrower,
    amount,
    tokenRepay,
    tokenCollateral,
    accountAddr,
    chainId,
    callBackCloseConfirm = () => { },
  ) =>
    async (dispatch, getState) => {
      try {
        const rifi = new Rifi(getProvider());
        const trx = await rifi.liquidateBorrow(
          borrower,
          amount,
          tokenRepay,
          tokenCollateral,
          { mantissa: true },
        );
        if (trx) {
          const wait = await trx.wait();
          if (wait.status === 1) {
            const tokensByNetWork = selectTokensByNetWork(getState());
            const { rToken } = tokensByNetWork;
            dispatch(getBalanceOfAllTokenBorrowed(accountAddr, chainId, rToken));
            dispatch(getBalanceOfAllToken(accountAddr, chainId, rToken));
            dispatch(setTransactionHash(wait.transactionHash));
          }
        }
        return trx;
      } catch (ex) {
        callBackCloseConfirm();
      }
    };

export const approveToken =
  (
    accountAddress,
    chainId,
    token_address,
    underlying_address,
    callBackCloseConfirm = () => { },
  ) =>
    async (dispatch, getState) => {
      try {
        const network = Rifi.util.getNetNameWithChainId(chainId);
        const provider = getProvider();
        // unit256;
        // Amount:
        // 1.157920892373162e+59
        const valueMax =
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
        const approve = await Rifi.eth.trx(
          underlying_address,
          'function approve(address _spender, uint256 _value) public returns (bool success)',
          [token_address, valueMax],
          {
            provider,
            network,
          },
        );
        if (approve) {
          const wait = await approve.wait();
          if (wait.status === 1) {
            const tokensByNetWork = selectTokensByNetWork(getState());
            const { rToken } = tokensByNetWork;
            dispatch(getAllowanceOfAllToken(chainId, accountAddress, rToken));
          }
        }
        return approve;
      } catch (ex) {
        if (
          ex?.error?.code === 4001 ||
          ex?.error?.message === 'User rejected the transaction'
        )
          callBackCloseConfirm();
      }
    };

export const checkMembershipOfAllToken =
  (accountAddress, chainId, listToken) => async (dispatch, getState) => {
    const result = await checkMembershipOfAllTokenSDK(
      accountAddress,
      chainId,
      listToken,
    );
    if (result) {
      dispatch(setCheckMemberships(result));
    }
    return result;
  };

export const enterMarket =
  (
    accountAddress,
    chainId,
    underlying_symbol,
    callBackCloseConfirm = () => { },
  ) =>
    async (dispatch, getState) => {
      try {
        const rifi = new Rifi(getProvider());
        const trx = await rifi.enterMarkets(underlying_symbol); // Use [] for multiple
        if (trx) {
          const wait = await trx.wait();
          if (wait.status === 1) {
            const tokensByNetWork = selectTokensByNetWork(getState());
            const { rToken } = tokensByNetWork;
            dispatch(checkMembershipOfAllToken(accountAddress, chainId, rToken));
          }
        }
        return trx;
      } catch (ex) {
        if (
          ex?.error?.code === 4001 ||
          ex?.error?.message === 'User rejected the transaction'
        )
          callBackCloseConfirm();
      }
    };

export const exitMarket =
  (
    accountAddress,
    chainId,
    underlying_symbol,
    callBackCloseConfirm = () => { },
  ) =>
    async (dispatch, getState) => {
      try {
        const rifi = new Rifi(getProvider());
        const trx = await rifi.exitMarket(underlying_symbol);
        if (trx) {
          const wait = await trx.wait();
          if (wait.status === 1) {
            const tokensByNetWork = selectTokensByNetWork(getState());
            const { rToken } = tokensByNetWork;
            dispatch(checkMembershipOfAllToken(accountAddress, chainId, rToken));
          }
        }
        return trx;
      } catch (ex) {
        if (
          ex?.error?.code === 4001 ||
          ex?.error?.message === 'User rejected the transaction'
        )
          callBackCloseConfirm();
      }
    };

export const updateRifiInfo =
  (accountAddress) => async (dispatch, getState) => {
    try {
      const result = await Rifi.rifi.getRifiAccrued(
        accountAddress,
        getProvider(),
      );
      dispatch(
        setRifiData({
          wallet: parseFloat(ethers.utils.formatUnits(result.balance, 18)),
          unclaimed: parseFloat(ethers.utils.formatUnits(result.allocated, 18)),
        }),
      );
    } catch (ex) { }
  };

export const claimRifi = () => async (dispatch) => {
  try {
    const rifi = new Rifi(getProvider());
    const trx = await rifi.claimRifi();
    trx && (await trx.wait());
  } catch (ex) { }
};

export const fetchListPrices = async (params = {}) => {
  try {
    const result = await http.get('prices', {
      params,
    });
    return result;
  } catch (err) { }
};

export const depositInVault = async (
  vaultAdress,
  amount,
  provider,
  tokenSymbol,
  chainId,
) => {
  try {
    const decimal = await getTokenDecimal(tokenSymbol, chainId);
    const amountWithDecimal = ethers.utils.parseUnits(amount, decimal);
    const result = await Rifi.eth.trx(
      vaultAdress,
      'function deposit(uint256 _amount)',
      [amountWithDecimal],
      { provider },
    );
    result && (await result.wait());
    return result;
  } catch (ex) {
    return ex;
  }
};

// export const withDrawInVault = async (
//   vaultAdress,
//   amount,
//   provider,
//   tokenSymbol,
// ) => {
//   try {
//     const decimal = await getTokenDecimal(tokenSymbol);
//     const amountWithDecimal = ethers.utils.parseUnits(amount, decimal);
//     const result = await Rifi.eth.trx(
//       vaultAdress,
//       'function withdraw(uint256 _amount)',
//       [amountWithDecimal],
//       { provider },
//     );
//     result && (await result.wait());
//     return result;
//   } catch (ex) {
//     console.error('withDrawInVault exception:', ex);
//     return ex;
//   }
// };

export const withDrawInVault = async (vaultLabel, amount) => {
  try {
    const rifi = new Rifi(getProvider());
    const result = await rifi.withdraw(vaultLabel, amount);
    result && (await result.wait());
    return result;
  } catch (ex) {
    return ex;
  }
};

export const withDrawAllInVault = async (cContractAddr, provider) => {
  try {
    const result = await Rifi.eth.trx(
      cContractAddr,
      'function withdrawAll()',
      [],
      { provider },
    );
    result && (await result.wait());
    return result;
  } catch (ex) {
    return ex;
  }
};

export const claimInVault = async (vaultLabel) => {
  try {
    const rifi = new Rifi(getProvider());
    const result = await rifi.claimReward(vaultLabel);
    result && (await result.wait());
    return result;
  } catch (ex) {
    return ex;
  }
};

export const getBalanceInVault = async (
  walletAddr,
  vaultAddr,
  chainId,
  provider,
) => {
  try {
    const network = Rifi.util.getNetNameWithChainId(chainId);
    const vaultAbi = [
      'function depositToken() view returns (address)',
      'function rewardToken() view returns (address)',
      'function getBalance(address) view returns (uint256)',
      'function getUnclaimedReward(address account) view returns (uint256)',
    ];
    const tokenAbi = ['function decimals() view returns (uint8)'];

    const [depositToken, rewardToken] = await Promise.all([
      Rifi.eth.read(vaultAddr, 'depositToken', [], {
        network,
        provider,
        abi: vaultAbi,
      }),
      Rifi.eth.read(vaultAddr, 'rewardToken', [], {
        network,
        provider,
        abi: vaultAbi,
      }),
    ]);

    const [balance, reward, balDecimals, rewDecimals] = await Promise.all([
      Rifi.eth.read(vaultAddr, 'getBalance', [walletAddr], {
        network,
        provider,
        abi: vaultAbi,
      }),
      Rifi.eth.read(vaultAddr, 'getUnclaimedReward', [walletAddr], {
        network,
        provider,
        abi: vaultAbi,
      }),
      Rifi.eth.read(depositToken, 'decimals', [], {
        network,
        provider,
        abi: tokenAbi,
      }),
      (async function () {
        if (ADDRESS_0 === rewardToken) {
          return 0;
        } else {
          return Rifi.eth.read(rewardToken, 'decimals', [], {
            network,
            provider,
            abi: tokenAbi,
          });
        }
      })(),
    ]);

    const balanceWithDecimals = ethers.utils.formatUnits(balance, balDecimals);
    const rewardWithDecimals = ethers.utils.formatUnits(reward, rewDecimals);
    return {
      deposit_token: depositToken,
      reward_token: rewardToken,
      balance_in_vault: balanceWithDecimals,
      balance_in_vault_bignumber: balance,
      reward_in_vault: rewardWithDecimals,
      network,
    };
  } catch (ex) { }
};

export const approveTokenOnVault =
  (chainId, vaultAdress, underlying_address) => async () => {
    try {
      const network = Rifi.util.getNetNameWithChainId(chainId);
      const provider = getProvider();
      const valueMax =
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
      const approve = await Rifi.eth.trx(
        underlying_address,
        'function approve(address _spender, uint256 _value) public returns (bool success)',
        [vaultAdress, valueMax],
        {
          provider,
          network,
        },
      );
      approve && (await approve.wait());
      return approve;
    } catch (ex) { }
  };

export const getTokenDecimal = async (tokenSymbol, chainId) => {
  try {
    const provider = getProvider();
    const network = Rifi.util.getNetNameWithChainId(chainId);
    const contractAddr = Rifi.util.getAddress(tokenSymbol, network);
    const decimal = await Rifi.eth.read(
      contractAddr,
      'function decimals() returns (uint8)',
      [],
      {
        network,
        provider,
      },
    );
    return decimal;
  } catch (error) { }
};

export const getUnderlyingPriceOfToken = async (tokenSymbol) => {
  try {
    const provider = getProvider();
    const rifi = new Rifi(provider);
    const underlyingPrice = await rifi.getUnderlyingPrice(tokenSymbol);
    return underlyingPrice;
  } catch (error) {
    return 0;
  }
};

export const estimateRepayBorrowFee = () => {
  const gasLimit = 190000;
  const gasPrice = BigNumber.from(10000000000); /*10 gwei*/

  return gasPrice.mul(gasLimit);
};

export const estimateSupplyFee = () => {
  const gasLimit = 160000;
  const gasPrice = BigNumber.from(10000000000); /*10 gwei*/

  return gasPrice.mul(gasLimit);
};

export const getRewardBalances = async (vaultLabel, chainId) => {
  try {
    const rifi = new Rifi(getProvider());
    const rewardBalances = await rifi.getRewardBalances(vaultLabel);
    const getRewards = rewardBalances.map(async (reward, index) => {
      let decimalOfToken = await getTokenDecimal(reward.symbol, chainId);
      const claimable = reward.claimable
        ? ethers.utils.formatUnits(reward.claimable, decimalOfToken)
        : 0;
      const pending = reward.pending
        ? ethers.utils.formatUnits(reward.pending, decimalOfToken)
        : 0;
      const vesting = reward.vesting
        ? ethers.utils.formatUnits(reward.vesting, decimalOfToken)
        : 0;

      return {
        symbol: reward.symbol,
        claimable: claimable,
        pending: pending,
        vesting: vesting,
      };
    });
    return Promise.all(getRewards);
  } catch (ex) { }
};

export const harvestReward = async (vaultLabel) => {
  try {
    const rifi = new Rifi(getProvider());
    const result = await rifi.harvestReward(vaultLabel);
    result && (await result.wait());
    return result;
  } catch (ex) {
    return ex;
  }
};

export const createNetworkOrSwitch = async () => {
  let ethereum = getProvider();
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x61' }],
    });
  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        const data = [
          {
            chainId: '0x61',
            chainName: 'Binance Smart Chain Testnet',
            nativeCurrency: {
              name: 'BNB',
              symbol: 'BNB',
              decimals: 18,
            },
            rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
            blockExplorerUrls: ['https://testnet.bscscan.com/'],
          },
        ];
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: data,
        });
      } catch (addError) {
        // handle "add" error
      }
    }
    // handle other "switch" errors
  }
};

export const handleSwitchNetwork = async (chainID) => {
  if (!chainID) return;
  let ethereum = window.ethereum;
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: `0x${Number(chainID).toString(16)}` }],
    });
  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        const data = NETWORK_INFO[chainID.toString()];
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: data,
        });
      } catch (addError) {
        // handle "add" error
      }
    }
    // handle other "switch" errors
  }
};

export const approve = async (
  chainId,
  tokenAddress,
  spenderAdress,
  callBack = () => { },
) => {
  try {
    const network = Rifi.util.getNetNameWithChainId(chainId);
    const provider = getProvider();
    const valueMax =
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
    const approve = await Rifi.eth.trx(
      tokenAddress,
      'function approve(address _spender, uint256 _value) public returns (bool success)',
      [spenderAdress, valueMax],
      {
        provider,
        network,
      },
    );

    approve && (await approve.wait());
    return approve;
  } catch (ex) {
    callBack();
    return ex;
  }
};

export const isApprove = async (
  chainId,
  acountAddress,
  tokenAddress,
  spenderAdress,
) => {
  try {
    const network = Rifi.util.getNetNameWithChainId(chainId);
    const provider = getProvider();
    const SAFE_ALLOWANCE = BigNumber.from('0xfffffffffffffffffffffff');

    const isApprove = await Rifi.eth.read(
      tokenAddress,
      'function allowance(address _owner, address _spender) public view returns (uint256 remaining)',
      [acountAddress, spenderAdress],
      { network, provider },
    );
    return isApprove.gt(SAFE_ALLOWANCE);
  } catch (ex) { }
};

export const requestBridge = async (
  senderAddress,
  receiverAddress,
  bridgeAddress,
  abi,
  amount,
  tokenSymbol,
  chainId,
) => {
  try {
    const decimal = await getTokenDecimal(tokenSymbol, chainId);
    const amountSwap = ethers.utils.parseUnits(amount.toString(), decimal);
    const web3 = new Web3(getProvider());
    const bridge = new web3.eth.Contract(abi, bridgeAddress);
    const request_bridge_call = await bridge.methods
      .lockRifi(receiverAddress, amountSwap)
      .send({ from: senderAddress });
    return request_bridge_call;
  } catch (ex) {
    console.log(ex);
    return false;
  }
};

export const getMaxAmountBridge = async (
  bridgeAddress,
  abi,
  tokenSymbol,
  chainId,
) => {
  try {
    const decimal = await getTokenDecimal(tokenSymbol, chainId);
    const web3 = new Web3(getProvider());
    const bridge = await new web3.eth.Contract(abi, bridgeAddress);
    const maxAmountBridge = await bridge.methods.MAX_AMOUNT_BRIDGE().call();
    return maxAmountBridge / Math.pow(10, decimal);
  } catch (ex) {
    return 0;
  }
};

export const getMaxAmountDailyBridge = async (
  bridgeAddress,
  abi,
  tokenSymbol,
  chainId,
) => {
  try {
    const decimal = await getTokenDecimal(tokenSymbol, chainId);
    const web3 = new Web3(getProvider());
    const bridge = await new web3.eth.Contract(abi, bridgeAddress);
    const maxAmountBridge = await bridge.methods.MAX_DAILY_BRIDGE().call();
    return maxAmountBridge / Math.pow(10, decimal);
  } catch (ex) {
    return 0;
  }
};

export const getBridgeUserData = async (
  senderAddress,
  bridgeAddress,
  abi,
  tokenSymbol,
  chainId,
) => {
  try {
    const decimal = await getTokenDecimal(tokenSymbol, chainId);
    const web3 = new Web3(getProvider());
    const bridge = await new web3.eth.Contract(abi, bridgeAddress);
    const result = await bridge.methods.bridgeUserData(senderAddress).call();
    return {
      timestamp: result.timestamp,
      bridgeAmount: result.bridgeAmount / Math.pow(10, decimal),
    };
  } catch (ex) {
    return 0;
  }
};

export const getStatusApp = (params) => async (dispatch, getState) => {
  try {
    const result = await getStatusPage();
    if (result && result.app_status) {
      dispatch(setStatusPageData(result.app_status));
    }
  } catch (ex) { }
};
