const SET_TRANSACTION_HASH = 'transaction/SET_TRANSACTION_HASH';

export const initialState = {
  hash: undefined,
};

export const setTransactionHash = (data) => ({
  type: SET_TRANSACTION_HASH,
  payload: {
    data: data,
  },
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSACTION_HASH:
      return {
        ...state,
        hash: action.payload.data,
      };
    default:
      return state;
  }
};
