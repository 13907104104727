const SET_RIFI_DATA = 'tokens/SET_RIFI_DATA';
const SET_DEFAULT_RIFI_DATA = 'tokens/SET_DEFAULT_RIFI_DATA';

export const initialState = {
  wallet: 0,
  unclaimed: 0,
  price: 0.1,
};

export const setRifiData = (data) => ({
  type: SET_RIFI_DATA,
  payload: data,
});

export const setDefaultRifiData = () => ({
  type: SET_DEFAULT_RIFI_DATA,
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RIFI_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SET_DEFAULT_RIFI_DATA:
      return {
        wallet: 0,
        unclaimed: 0,
        price: 0.1,
      };
    default:
      return state;
  }
};
