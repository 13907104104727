import http from './http';
import * as api from './router_api';

export const getVaultInfo = async (params) => {
  try {
    const res = await http.get(api.VAULTS, {
      params: params,
    });
    if (res) return res;
  } catch (err) {
    throw err;
  }
};

export const getVaultDetailData = async (slug, params) => {
  try {
    const result = await http.get(api.VAULTS + `/${slug}`, {
      params: params,
    });
    if (result) {
      return result;
    }
  } catch (err) {
    throw err;
  }
};

export const getDataLiquidate = async (params) => {
  try {
    const result = await http.get('/liquidity/accounts', {
      params,
    });
    if (result) {
      return result;
    }
  } catch (err) {
    throw err;
  }
};

export const getStatusPage = async (params) => {
  try {
    const result = await http.get('/app_status', {
      params,
    });
    if (result) {
      return result;
    }
  } catch (err) {
    throw err;
  }
};

export const getPricesOfToken = async () => {
  try {
    const result = await http.get('/vaults/prices');
    if (result) {
      return result;
    }
  } catch (err) {
    throw err;
  }
};

export const getNFT = async (params) => {
  try {
    const result = await http.get('/nfts', {
      params: params,
      baseURL: process.env.REACT_APP_API_NFT,
    });
    if (result) {
      return result;
    }
  } catch (err) {
    throw err;
  }
};

export const getNFTCount = async (params, baseUrl = '') => {
  try {
    const result = await http.get(`/nfts/count`, {
      params: { ...params },
      baseURL: process.env.REACT_APP_API_NFT,
    });
    if (result) {
      return result;
    }
  } catch (error) {}
};
