export const LOGIN_GREETING_PATH = '/login';
export const HOME_PATH = '/';
export const DASHBOARD_PATH = '/';
export const MARKETS_PATH = '/markets';
export const COMING_SOON = '/coming';
export const PORTFOLIO = '/portfolio';
export const MARKET_ANALYSIS = '/market-analysis';
export const PRICE = '/price';
export const GOVERNANCE = '/governance';
export const CONTACT = '/contact';
export const FEEDBACK = '/feedback';
export const WHITEPAPER = '/whitepaper';
export const FAQ = '/faq';
export const VAULT = '/vault';
export const LIQUIDATE = '/liquidate';
export const BRIDGE = '/bridge';
