const SET_DATA_WAITING = 'waiting/SET_DATA_WAITING';
const SET_DATA_WAITING_BALANCE = 'waiting/SET_DATA_WAITING_BALANCE';
const CLOSE_WAITING = 'waiting/CLOSE_WAITING';
const CLOSE_WAITING_BALANCE = 'waiting/CLOSE_WAITING_BALANCE';

export const TYPE_WAITING = {
  LOADING: 'LOADING',
  LOADINGBALANCE: 'LOADINGBALANCE',
  SUPPLY: 'SUPPLY',
  REDEEM: 'REDEEM',
  BORROW: 'BORROW',
  REPAY: 'REPAY',
};

export const initialState = {
  isLoading: true,
  isLoadingBalance: false,
  type: TYPE_WAITING['LOADING'],
  data: {},
};

export const setDataWaiting = (type = TYPE_WAITING['LOADING'], data = {}) => ({
  type: SET_DATA_WAITING,
  payload: { type, data },
});

export const setDataWaitingBalance = (
  type = TYPE_WAITING['LOADINGBALANCE'],
  data = {},
) => ({
  type: SET_DATA_WAITING_BALANCE,
  payload: { type, data },
});

export const closeWaiting = (type = TYPE_WAITING['LOADING'], data = {}) => ({
  type: CLOSE_WAITING,
  payload: { type, data },
});
export const closeWaitingBalance = (
  type = TYPE_WAITING['LOADINGBALANCE'],
  data = {},
) => ({
  type: CLOSE_WAITING_BALANCE,
  payload: { type, data },
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_WAITING:
      return {
        ...state,
        isLoading: true,
        type: action.payload.type,
        data: action.payload.data,
      };
    case CLOSE_WAITING:
      return {
        ...state,
        isLoading: false,
        type: action.payload.type,
        data: action.payload.data,
      };
    case SET_DATA_WAITING_BALANCE:
      return {
        ...state,
        isLoadingBalance: true,
        type: action.payload.type,
        data: action.payload.data,
      };
    case CLOSE_WAITING_BALANCE:
      return {
        ...state,
        isLoadingBalance: false,
        type: action.payload.type,
        data: action.payload.data,
      };

    default:
      return state;
  }
};
