export const CHOOSE_WALLET = 'CHOOSE_WALLET';
export const CONNECT_WALLET = 'CONNECT_WALLET';
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const START_CONNECT = 'START_CONNECT';
export const ACTION_BACK = 'ACTION_BACK';
export const ACTION_BACK_TYPE_WALLET = 'ACTION_BACK_TYPE_WALLET';

export const initialState = {
  isOpen: false,
  type: CHOOSE_WALLET,
  isLoading: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        type: CHOOSE_WALLET,
        isOpen: true,
      };
    case START_CONNECT:
      return {
        ...state,
        isLoading: true,
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        isOpen: false,
        isLoading: false,
      };
    case CHOOSE_WALLET:
      return {
        ...state,
        type: CHOOSE_WALLET,
      };
    case CONNECT_WALLET:
      return {
        ...state,
        type: CONNECT_WALLET,
      };
    case ACTION_BACK:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_BACK_TYPE_WALLET:
      return {
        ...state,
        isLoading: false,
        type: CHOOSE_WALLET,
      };
    default:
      return state;
  }
};
