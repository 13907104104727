import React from 'react';
import { Container } from 'react-bootstrap';
import MenuHeader from './MenuHeader';

const Header = (props) => {
  return (
    <div className='header'>
      <Container>
        <MenuHeader {...props} />
        <div className='introduce'>
          <div className='title1'>Market Overview</div>
          <span className='title2'>
            Community - built interfaces integrating the protocol
          </span>
        </div>
      </Container>
    </div>
  );
};

export default Header;
