const SET_DATA_STATUS_PAGE = 'SET_DATA_STATUS_PAGE';

export const initialState = {
  app_status: undefined,
};

export const setStatusPageData = (data) => ({
  type: SET_DATA_STATUS_PAGE,
  payload: data,
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_STATUS_PAGE:
      return {
        ...state,
        app_status: action.payload,
      };
    default:
      return state;
  }
};
