export const RESET_ADDRESS = 'RESET_ADDRESS';
export const RESET_SUPPLY = 'RESET_SUPPLY';
export const RESET_REPAY = 'RESET_REPAY';
export const RESET_VALUES = 'RESET_VALUES';

export const initialState = {
  values: undefined,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ADDRESS:
      return {
        ...state,
        values: {
          ...state.values,
          address: undefined,
        },
        fields: {
          ...state.fields,
          address: undefined,
        },
      };
    case RESET_SUPPLY:
      return {
        ...state,
        values: {
          ...state.values,
          supply_coins: undefined,
        },
        fields: {
          ...state.fields,
          supply_coins: undefined,
        },
      };
    case RESET_REPAY:
      return {
        ...state,
        values: {
          ...state.values,
          borrow_coins: undefined,
        },
        fields: {
          ...state.fields,
          borrow_coins: undefined,
        },
      };
    case RESET_VALUES:
      return {
        ...state,
        values: undefined,
        fields: undefined,
      };
    default:
      return state;
  }
};
