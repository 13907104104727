import { createSelector } from 'reselect';

export const selectTokensBlock = createSelector(
  (state) => state.tokensPublic,
  (tokensPublic) => tokensPublic.tokensBlock,
);
export const selectTokensCurrent = createSelector(
  (state) => state.tokensPublic,
  (tokensPublic) => tokensPublic.tokensCurrent,
);

export const selectTokensByNetWork = createSelector(
  (state) => state.tokensByNetWork,
  (tokensByNetWork) => tokensByNetWork.tokens,
);
export const selectTokensDefault = createSelector(
  (state) => state.tokensByNetWork,
  (tokensByNetWork) => tokensByNetWork.tokensDefault,
);

export const selectTokenBlockByTokenAddress = createSelector(
  selectTokensBlock,
  (_, tokenAddress) => tokenAddress,
  (tokensBlock, tokenAddress) =>
    tokensBlock.find((token) => token.token_address === tokenAddress),
);

export const selectAllowances = createSelector(
  (state) => state.tokensWithWallet,
  (tokensWithWallet) => tokensWithWallet.allowances,
);

export const selectBalances = createSelector(
  (state) => state.tokensWithWallet,
  (tokensWithWallet) => tokensWithWallet.balancesOfTokenInWallet,
);

export const selectInforAccountWallet = createSelector(
  (state) => state.tokensWithWallet,
  (tokensWithWallet) => tokensWithWallet.infoAccountWallet,
);

export const selectAccountsInInforAccWallet = createSelector(
  selectInforAccountWallet,
  (inforAccountWallet) => inforAccountWallet && inforAccountWallet.accounts,
);

export const selectBalancesSupplied = createSelector(
  (state) => state.tokensWithWallet,
  (tokensWithWallet) => tokensWithWallet && tokensWithWallet.balancesSupplied,
);

export const selectCheckMemberShips = createSelector(
  (state) => state.tokensWithWallet,
  (tokensWithWallet) => tokensWithWallet && tokensWithWallet.checkMemberShips,
);

export const selectBalancesBorrowed = createSelector(
  (state) => state.tokensWithWallet,
  (tokensWithWallet) => tokensWithWallet && tokensWithWallet.balancesBorrowed,
);

export const selectWaiting = createSelector(
  (state) => state,
  (state) => state.waiting,
);

export const selectConnectWallet = createSelector(
  (state) => state,
  (state) => state.connectWallet,
);

export const selectRifi = createSelector(
  (state) => state,
  (state) => state.rifi,
);

export const selectTransactionHash = createSelector(
  (state) => state.transaction,
  (transaction) => transaction && transaction.hash,
);

export const selectStatusPage = createSelector(
  (state) => state.statusPage,
  (statusPage) => statusPage && statusPage.app_status,
);
