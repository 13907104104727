import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import persistState from 'redux-localstorage';
import { routerMiddleware } from 'connected-react-router';
import { pick } from 'lodash';
import createRootReducer from './reducers';
import { initialState as tokensWithWalletState } from './reducers/tokensWithWallet';
import { initialState as tokensByNetworkState } from './reducers/tokensByNetWork';

export const history = createBrowserHistory();

const enhancers = [
  persistState(undefined, {
    key: process.env.REACT_APP_NAME,
    slicer: (paths) => (state) => {
      // filter which substore to persist
      const subset = {
        tokensWithWallet: {
          ...tokensWithWalletState,
          ...pick(state.tokensWithWallet, 'balancesOfTokenInWallet'),
          ...pick(state.tokensWithWallet, 'allowances'),
          ...pick(state.tokensWithWallet, 'balancesSupplied'),
          ...pick(state.tokensWithWallet, 'balancesBorrowed'),
          ...pick(state.tokensWithWallet, 'checkMemberShips'),
        },
        tokensByNetWork: {
          ...tokensByNetworkState,
          ...pick(state.tokensByNetWork, 'tokens'),
          ...pick(state.tokensByNetWork, 'tokensDefault'),
        },
      };
      return subset;
    },
  }),
];

const middleware = [thunk, routerMiddleware(history)];

function configureStore(preloadedState) {
  const composeEnhancer =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(applyMiddleware(...middleware), ...enhancers),
  );

  return store;
}

const store = configureStore({});

export const getStore = () => store;

export default store;
