import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-table/react-table.css';
import 'react-circular-progressbar/dist/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store, { history } from './configureStore';
import { Web3ReactProvider } from '@web3-react/core';
// import firebase from 'firebase/app';
import 'firebase/analytics';
// import { firebaseConfig } from 'configs/firebase';
import { providers } from 'ethers';

export function getLibrary(provider) {
  const library = new providers.Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

ReactDOM.render(
  <Provider store={store}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <App history={history} />
    </Web3ReactProvider>
  </Provider>,
  document.getElementById('root'),
);

// firebase.initializeApp(firebaseConfig);
// firebase.analytics();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
